html,body {
   width: 100%;  margin-top: 0; margin-left: 0; margin-right: 0;
 }
.about {
  width: 97%;
  max-width: 900px;
  margin-left: 1%;
  font-size: 1em;
  letter-spacing:0.8px;
}
.about img {
  width: 100%;
  max-width: 900px;
}
.about video {
  width: 100%;
  max-width: 900px;
}
 
.about table {
  border-collapse: collapse;
  margin: 0 auto;
  text-align: left;
}

.about table img {
  width: 42px;
  max-width: 900px;
}
.about table td,
table th {
  border: 1px solid #cad9ea;
  color: #666;
  height: 32px;
}
.about table thead th {
  background-color: #cce8eb;
  width: 100px;
}
.about table tr:nth-child(odd) {
  background: #fff;
}
.about table tr:nth-child(even) {
  background:rgba(93,225,63,0.1)
}

// .footer{ position:fixed;bottom:  0; height: 80px; width: 100%; align-items: center;}