
.foot{
    position: fixed;
    bottom: 0;
    width: 100%; 
    background:white; border-top: rgba(0, 0, 0, .06) 1px dotted;
   }
   body{
	margin: auto;
	margin-left: 0px;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
}
   